import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import RouterHook from './router/router-hook'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
import {Boot} from '@wangeditor/editor'

// 不使用视频自定义菜单
// import MyEditVideoSizeMenu from "@/components/editor/MyEditVideoSizeMenu";
// const myVideoSizeMenuConf = {
//   key: 'myVideoSize', // 定义 menu key ：要保证唯一、不重复（重要）
//   factory() {
//     return new MyEditVideoSizeMenu() // 把 `YourMenuClass` 替换为你菜单的 class
//   },
// }
// const module = {
//   menus: [myVideoSizeMenuConf, ]
// }
// Boot.registerModule(module);
Boot.registerModule(attachmentModule);
RouterHook(router)

const app = createApp(App)
app.use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app')
