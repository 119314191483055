import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/user/login.vue')
  },
  {
    path: '/modifyPwd',
    name: 'modifyPwd',
    meta: {auth: true},
    component: () => import('../views/user/modifyPwd.vue')
  },
  {
    path: '/audioConvert',
    name: 'audioConvert',
    meta: {auth: false},
    component: () => import('../views/audio/convert.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {auth: true},
    component: () => import('../views/dashboard/index.vue'),
    children: [
      {
        path: '/account',
        name: 'account',
        meta: {auth: true},
        component: () => import('../views/account/index.vue')
      },
      {
        path: '/article',
        name: 'article',
        meta: {auth: true},
        component: () => import('@/views/article/index.vue')
      },
      {
        path: '/bpm',
        name: 'bpm',
        meta: {auth: true},
        component: () => import('@/views/bpm/index.vue')
      },
    ]
  },
  {
    path: '/pub/blog/:id',
    name: 'pubBlog',
    props: true,
    meta: {auth: false},
    component: () => import('../views/pub/blog/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  // 未知路由重定向
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
