import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken } from '@/utils/auth'
import store from '@/store'
import router from "@/router";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60
})

// 添加请求拦截器
instance.interceptors.request.use(
    (config) => {
      // 获取访问令牌
      // const token = getToken()
      // 如果令牌存在，则追加到header中。服务器从token获取就可以了
      // if (token) config.headers['token'] = token
      return config
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error)
    }
)

// 添加响应拦截器
instance.interceptors.response.use((
    response) => {
        if (response.data.return_code === '0') {
            return response.data.return_data
        }
        if (response.data.return_code !== '1008') {
            // 获取用户信息的错误码，不弹出提示
            ElMessage.error(response.data.return_msg) // 错误提示
        } else {
            return response.data.return_data
        }
        if (response.data.return_code === '1002') {
            // 登录过期
            router.push('/login');
            // location.reload()
            // store.dispatch('user/resetToken').then(() => {
            //     location.reload()
            // })
        }
        return Promise.reject(response)
    },
    (error) => {
        ElMessage.error(error.message) // 错误提示
        return Promise.reject(error)
    }
)
export default instance