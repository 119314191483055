<template>
  <div class="app-container">
    <div>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect">
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item v-if="!!!store.state.user.userId" index="2">登录</el-menu-item>
        <el-sub-menu v-else index="3">
          <template #title>{{ store.state.user.name }}</template>
          <el-menu-item index="3-1">进入控制台</el-menu-item>
          <el-menu-item index="3-2">修改密码</el-menu-item>
          <el-menu-item index="3-3">退出登录</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div >
      <el-row class="item-wrapper">
        <el-col :span="2"></el-col>
        <el-col :span="4"><div class="item-title">图片压缩</div></el-col>
        <el-col :span="1"><div class="split-line"/></el-col>
        <el-col :span="17"><div class="item-content">选择图片上传，并设置图片质量进行压缩，quality在0-1之间。服务器处理完成后返回压缩后的图片。</div></el-col>
      </el-row>
      <el-row class="item-wrapper">
        <el-col :span="1"></el-col>
        <el-col :span="22"><el-divider></el-divider></el-col>
        <el-col :span="1"></el-col>
      </el-row>
      <el-row class="item-wrapper">
        <el-col :span="2"></el-col>
        <el-col :span="4"><div class="item-title">音频格式转换</div></el-col>
        <el-col :span="1"><div class="split-line"/></el-col>
        <el-col :span="17"><div class="item-content">将音频文件转换为选定的音频格式。</div></el-col>
      </el-row>
    </div>

  </div>
  <div class="footer">
    <a href="https://beian.miit.gov.cn" target="_blank"><span>粤ICP备18087157号-1</span></a>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import {ref} from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const activeIndex = ref("1")

const handleSelect = (key, keyPath) =>  {
    switch (key){
      case "1":
        router.push('/')
        break;
      case "2":
        router.push('/login')
        break;
      case "3-1":
        router.push('/dashboard')
        break;
      case "3-2":
        router.push('/modifyPwd')
        break;
      case "3-3":
        store.dispatch("user/logout").then(() => {
          router.push('/')
        })
        break;
      default:
        break;
    }
}
</script>
<style scoped lang="scss">
.app-container {
  width: 100%;
  margin-bottom: 30px;
}
.item-wrapper {
  margin-top: 50px;
}
.item-title {
  text-align: center;
  margin: 10px;
}
.item-content {
  text-align: left;
  margin: 10px;
}
.split-line {
  width: 2px;
  height: 100%;
  background-color: #bcbcbc;
  margin: 0 8px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}
</style>